@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

html, body{
    font-family: "Sora", sans-serif;
    background-color: black;
    color: white;
}

.bg-one{
    height: 70vh;
    width: 50vw;
    position: absolute;
    top: -5rem;
    left: -20rem;
    background-size: cover;
    // background-image: url('/bg.png');
}

.bg-two{
    height: 70vh;
    width: 50vw;
    position: absolute;
    background-size: cover;
    bottom: 10%;
    right: 0rem;
    // background-image: url('/bg.png');
}

.bg-gray-40{
    --tw-bg-opacity: 1;
    background-color: rgb(16,16,16);
}

.homepage-howto-section{
    width: 100%;
//     background: #16222A;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to right, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height: 20rem;
    .content, .image-part{
        // padding: 2.5rem 4rem;
    }
}

.section-gradient{
    background-image: radial-gradient(96% 70% at 60% 80%, rgba(77 78 81 / 62%) 0, rgba(8, 11, 17, 0) 60%);
    @media (min-width: 500px){
        background-image: radial-gradient(70% 240% at 78% 45%, rgb(77 78 81 / 62%) 0, rgba(8, 11, 17, 0) 40%);
    }
}

.reverse-gradient{
    background-image: radial-gradient(96% 70% at 60% 80%, rgba(77 78 81 / 62%) 0, rgba(8, 11, 17, 0) 60%);
    @media (min-width: 500px){
        background-image: radial-gradient(70% 240% at 22% 45%, rgb(77 78 81 / 62%) 0, rgba(8, 11, 17, 0) 40%);
    }
}

.faqs-section{  
    .faqs-section-container{
        background: radial-gradient(50.55% 50.55% at 50% 23.37%, rgb(73 73 73 / 50%) 0, rgba(0, 0, 0, 0.62) 98.96%);
    
        .faq-row-wrapper{
            padding-top: 2rem;
            padding-bottom: 2rem;
            background: none;
            @apply px-5 md:px-10 rounded-2xl;
            color: white;
            .faq-row{
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid rgba(255,255,255,.05);
                .row-title{
                    @apply py-4 text-2xl;
                }
                .row-title-text, .icon-wrapper, .row-content, .row-content-text{
                    color: white !important;
                    position: static !important;
                }
            }
        }
    }
}

.text-teal-400{
    color: rgb(45 212 191);
}

.pre-register-gradient{
    background: radial-gradient(50.55% 50.55% at 50% 23.37%, rgb(73 73 73 / 50%) 0, rgba(0, 0, 0, 0.62) 98.96%);
}